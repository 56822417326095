<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1 style="text-align: center">Register</h1>
                <p style="text-align: center" class="text-muted">
                  Create your account
                </p>
                <CInput
                  placeholder="Your Name"
                  v-model="form.name"
                  autocomplete="name"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <p class="text-red-500" v-if="errors.name">
                  <small>
                    {{ errors.name[0] }}
                  </small>
                </p>
                <CInput
                  placeholder="Your Preferred Initials"
                  v-model="form.initials"
                  autocomplete=""
                  prepend="AB"
                />
                <p class="text-red-500" v-if="errors.initials">
                  <small>
                    {{ errors.initials[0] }}
                  </small>
                </p>
                <CInput
                  placeholder="Email"
                  v-model="form.email"
                  autocomplete="email"
                  prepend="@"
                />
                <p class="text-red-500" v-if="errors.email">
                  <small>
                    {{ errors.email[0] }}
                  </small>
                </p>
                <CInput
                  placeholder="Password"
                  type="password"
                  autocomplete="new-password"
                  v-model="form.password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <p class="text-red-500" v-if="errors.password">
                  <small>
                    {{ errors.password[0] }}
                  </small>
                </p>
                <CInput
                  placeholder="Repeat password"
                  v-model="form.password_confirmation"
                  type="password"
                  autocomplete="new-password"
                  class="mb-4"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton @click="submit_register" color="success" block
                      >Create Account<span v-if="loading" class="ml-2"
                        ><img class="gif" src="/img/loading-sm.gif"/></span
                    ></CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" to="/a/forgot" class="px-0"
                      >Forgot password?</CButton
                    >
                    <CButton color="link" to="/a/login" class="ml-4 px-0"
                      >Login</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "Register",
  data() {
    return {
      loading: false,
      form: {
        name: "",
        initials: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      errors: [],
    };
  },
  methods: {
    submit_register() {
      const axios = require("axios");
      console.log(this.form);
      var vm = this;
      vm.loading = true;
      axios
        .post("/register", this.form)
        .then((res) => {
          console.log(res);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          vm.loading = false;
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          vm.errors = err.response.data.errors;
          console.log("errors", vm.errors);
          vm.loading = false;

          Swal.fire("Oops...", err.message, "error");
        });
    },
  },
};
</script>

<style scoped>
.text-red-500 {
  color: red;
}
.gif {
  max-height: 20px;
  max-width: 20px;
}
</style>
